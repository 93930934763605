import React, { useState, useEffect, useCallback,useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Typography, Button, Grid, Box, Paper, CircularProgress,styled } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';


import MultipleChoiceQuestion from '../../components/MultipleChoice';
import { autosaveAnswer, fetchExamDetails, fetchExamTime,moveNextModule,skipBreak } from '../../actions/auth';
import FocusLock from 'react-focus-lock';
import BreakPage from '../../components/BreakPage';
import ReviewPageComponent from '../../components/ReviewPage';
import './testpages.css'
import debounce from 'lodash/debounce';
import DOMPurify from 'dompurify';
import { Snackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import Footer from './Footer';
import CalculatorComponent from '../../components/CalculatorComponent';
import TopHeader from './TopHeader';
import LoadingOverlay from '../../components/LoadingOverlay';
//import the css
import '../css/app.css'
import '../css/bootstrap45.min.css'
import '../css/simplebar.css'

import Countdown1 from '../../components/Countdown';
const TestPage = () => {
    const dispatch = useDispatch();
    const { studentexamId } = useParams();
// console.log('debounce')
    const debouncedDispatch = useCallback(debounce((questionId, value) => {
      saveCurrentAnswer(questionId, value);
      // console.log('tried to save:',questionId,value);

  }, 2000), [dispatch, studentexamId]);

  const [lastSavedAnswer, setLastSavedAnswer] = useState({});
  const [showReviewPage, setShowReviewPage] = useState(false);
    const navigate = useNavigate();
    const [currentExam, setCurrentExam] = useState(null);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [selectedAnswers, setSelectedAnswers] = useState({});
    const [markedForReview, setMarkedForReview] = useState({});

    const [crossedOut, setCrossedOut] = useState({});

    const [crossedIcon, setCrossedIcon] = useState(false);


    const [endTime, setEndTime] = useState(0);

    const [breakendTime, setBreakendTime] = useState(0);
    const [onBreak, setOnBreak] = useState(false);
    const [breakTimeLeft, setBreakTimeLeft] = useState(0);
    const [calculatorVisible, setCalculatorVisible] = useState(false);
    const [referenceVisible, setReferenceVisible] = useState(false);

    const [calculatorPosition, setCalculatorPosition] = useState({ x: 0, y: 0 });
    const [referencePosition, setReferencePosition] = useState({ x: 0, y: 0 });
    const minimapRef = useRef(null); // Add this line

    const [isCalculatorExpanded, setIsCalculatorExpanded] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const user = useSelector(state => state.auth.user);
    const [isMinimapVisible, setIsMinimapVisible] = useState(false);
    const [userInput, setUserInput] = useState('');
    const [inputError, setInputError] = useState('');
    const myCalculatorRef = useRef(null)
    const currentQuestionIdRef = useRef(null); // Add a ref for the current question ID
    const currentAnswerRef = useRef(null); // Add a ref for the current answer
  // console.log('currentQ',currentQuestionIdRef,currentAnswerRef)
	const toggleCalculator = () => {
    setCalculatorVisible(!calculatorVisible);
	};

  const processHtmlContent = (html) => {
    return html.replace(/<p>/gi, '<span>').replace(/<\/p>/gi, '</span>');
  };
  const toggleMarkForReview = (questionId) => {
    setMarkedForReview(prev => ({
      ...prev,
      [questionId]: !prev[questionId]
    }));
  };

  

  const toggleCrossedOut = (questionId, choiceIndex) => {
    // console.log('choice index', choiceIndex, 'questionId', questionId);
    setCrossedOut(prev => {
      // Ensure the questionId entry exists and is an array, otherwise start with an array of falses.
      const currentChoices = prev[questionId] || Array.from({ length: 4}, () => false);
      
      return {
        ...prev,
        [questionId]: currentChoices.map((item, index) => 
          index === choiceIndex ? !item : item
        )
      };
    });
  };

  const toggleTrueCrossedOut = (questionId, choiceIndex) => {
    setCrossedOut(prev => {
      // Check if there is already an entry for this question; if not, initialize with an array of false values.
      const currentChoices = prev[questionId] || Array.from({ length: 4 }, () => false);
  // console.log(currentChoices);
      // Return the new state with the specified choiceIndex set to true, while other choices retain their current state.
      return {
        ...prev,
        [questionId]: currentChoices.map((item, index) => 
          index === choiceIndex ? false : item  // Set the element at choiceIndex to true, others are unchanged.
        )
      };
    });
  };
  
  

  

	const toggleReference =  () => {
    setReferenceVisible(!referenceVisible);
	};
    const validateInput = useCallback((value) => {
      // Check for more than one dot or slash
      if (value.split('.').length > 2 || value.split('/').length > 2) {
          setInputError("You've entered a decimal point or slash in the wrong position");
          return false;
      }

      if (value.endsWith('/') || value.startsWith('/') || value.endsWith('.') || value.endsWith('-')) {
          setInputError("Incomplete numeric input");
          return false;
      }
      setInputError('');
      return true;
  }, []);


  const setMiniMapFalse = () => {
    setIsMinimapVisible(false);
  };
  const handleInputChange = (event) => {
    const value = event.target.value;
    if (/^[0-9./-]{0,5}$/.test(value)) {
        setUserInput(value);
        validateInput(value);

        // Update selectedAnswers state immediately for numerical answers
        const currentQuestionId = currentExam.current_module.questions[currentQuestionIndex].id;
        setSelectedAnswers(prev => ({ ...prev, [currentQuestionId]: value }));
        currentQuestionIdRef.current = currentQuestionId;
        currentAnswerRef.current = value;

    }
};

const toggleMinimap = () => {
  setIsMinimapVisible(prevVisibility => !prevVisibility);
};


const openReviewPage = () => {
  const currentQuestionId = currentExam?.current_module?.questions[currentQuestionIndex]?.id;
  const currentAnswer = selectedAnswers[currentQuestionId];
  saveCurrentAnswer(currentQuestionId, currentAnswer); // Directly save the current answer
  setShowReviewPage(true);
};

const closeReviewPage = () => {
  const currentQuestionId = currentExam?.current_module?.questions[currentQuestionIndex]?.id;
  const currentAnswer = selectedAnswers[currentQuestionId];
  saveCurrentAnswer(currentQuestionId, currentAnswer); // Directly save the current answer

  setShowReviewPage(false);

};

const saveCurrentAnswer = (questionId, currentAnswer) => {
  const lastAnswer = lastSavedAnswer[questionId];
  if (currentAnswer !== lastAnswer && validateInput(currentAnswer)) {
    // console.log('Saving answer for question:', questionId, currentAnswer);
    dispatch(autosaveAnswer(questionId, currentAnswer, studentexamId));
    setLastSavedAnswer(prev => ({ ...prev, [questionId]: currentAnswer }));
  }
};




const handleInputBlur = () => {
  if (userInput && validateInput(userInput)) {
    const currentQuestionId = currentExam.current_module.questions[currentQuestionIndex].id;
    setSelectedAnswers(prev => ({ ...prev, [currentQuestionId]: userInput }));
    saveCurrentAnswer(currentQuestionId, userInput); // Directly save the current answer
  }
};

  


    
    const syncTimeWithServer = useCallback(async () => {
      try {
          const timeData = await fetchExamTime(studentexamId);
          setBreakendTime(timeData.break_end_time);
          // console.log('on break',onBreak)
          setEndTime(timeData.end_time)

          if (onBreak) {
            // console.log('set null')
            setEndTime(null)

          }
          setOnBreak(timeData.on_break);
          // console.log('sync')
          // console.log(breakendTime,'ent time')

      } catch (error) {
          console.error('Error syncing time:', error);
      }
  }, [studentexamId]);


  
  const isMathModule = useCallback(() => {
    return currentExam && currentExam.current_module && currentExam.current_module.mathMode;
}, [currentExam]);


const fetchExamStatus = useCallback(async () => {
  setLoading(true);
  try {
    const { exam, savedAnswers } = await fetchExamDetails(studentexamId, navigate);
    if (!exam) {
      // setError('Exam data not found');
      setLoading(false);
      return;
    } else {
      // console.log('printing exam',exam);
    }

    setCurrentExam(exam);

    if (exam.on_break) {
      // const currentBreakTime = new Date(exam.break_end_time).getTime() - Date.now();
      // console.log(exam.break_end_time);
      
      setBreakendTime(exam?.break_end_time);
      // console.log('break',breakendTime)
      setOnBreak(true);
      // console.log('set null')
      setEndTime(null)

      // console.log('starting a break');
      // setBreakTimeLeft(Math.max(0, currentBreakTime / 1000));
    } else {
      setOnBreak(false);
      setBreakTimeLeft(0);
      syncTimeWithServer();
    }

    const answersMap = savedAnswers.reduce((acc, answerObj) => {
      acc[answerObj.question] = answerObj.answer;
      return acc;
    }, {});
    // console.log('answersMap',answersMap);
    setSelectedAnswers(answersMap);
    if (exam.current_module.questions.length > 0) {
      currentQuestionIdRef.current = exam.current_module.questions[0].id;
      currentAnswerRef.current = answersMap[exam.current_module.questions[0].id] || '';
  }
  } catch (err) {
    console.error('Error fetching exam status:', err);
    if (err.response && err.response.status === 403) {
      const errorMessage = err.response.data.error || '';
      if (errorMessage === 'Exam already completed.') {
        navigate(`/completed/${studentexamId}`);
      } else {
        navigate('/my-exams');
      }
    } else {
      setError('Failed to load exam data');
    }
  } finally {
    setLoading(false);
  }
}, [studentexamId, navigate, syncTimeWithServer]);


const handleModuleTransition = useCallback(async () => {
// console.log('handle module transition')

  try {
    setLoading(true)

    setSnackbarOpen(true);
    const nextModuleResponse = await moveNextModule(studentexamId);
    if (nextModuleResponse.status === 'Exam completed successfully') {
      navigate('/my-exams');
    } else {
      setCalculatorVisible(false);

      setCalculatorPosition({ x: 0, y: 0 });
      setReferencePosition({ x: 0, y: 0 });

      setIsCalculatorExpanded(false); // Reset expanded state
      setShowReviewPage(false);
      setCurrentQuestionIndex(0); // Reset question index for the new module
      fetchExamStatus(); 
    }
    
      

  } catch (error) {
    console.error('Error during module transition:', error);
    
    // setError('Failed to transition to the next module or complete the exam');
  } finally {
    // setLoading(false); // Deactivate loading screen
    // setLoading(false);

    // console.log("Transition ended");


  }
}, [studentexamId, navigate, fetchExamStatus]);


  //   const handleClickOutside = (event) => {
  //     const qlistButton = document.getElementById('qlist-button'); // Get the toggle button by its ID
  
  //     if (minimapRef.current && !minimapRef.current.contains(event.target) && !qlistButton.contains(event.target)) {
  //       setMiniMapFalse(); // Close the minimap
  //     }
  // };
  


  useEffect(() => {
    fetchExamStatus();
    // const timerSyncInterval = setInterval(syncTimeWithServer, 20000);
    // return () => clearInterval(timerSyncInterval);
  }, [fetchExamStatus]);



  useEffect(() => {
        const handleBeforeUnload = (e) => {
            e.preventDefault();
            e.returnValue = 'Are you sure you want to leave?';
        };

        window.addEventListener('beforeunload', handleBeforeUnload);
        return () => window.removeEventListener('beforeunload', handleBeforeUnload);
    }, []);




  //   useEffect(() => {
  //     // console.log('printing break time',breakTimeLeft);
  //     const interval = setInterval(() => {
  //         if (onBreak && breakTimeLeft > 0) {
  //             setBreakTimeLeft(prev => prev - 1);
  //         }
  //     }, 1000);
  //     return () => clearInterval(interval);
  // }, [onBreak, breakTimeLeft]);




    const handleSkipBreak = useCallback(async () => {
      try {
          await skipBreak(studentexamId); // Assuming studentexamId is available in your component
          setOnBreak(false);
          fetchExamStatus(); 
      } catch (error) {
          console.error('Error during skipping break:', error);
          setError('Failed to skip break');
      }
  }, [studentexamId, fetchExamStatus]);
    



  const handleNextQuestion = () => {
    const currentQuestionId = currentExam?.current_module?.questions[currentQuestionIndex]?.id;
    const currentAnswer = selectedAnswers[currentQuestionId];
    if (currentQuestionIndex < currentExam?.current_module?.questions.length - 1) {
      saveCurrentAnswer(currentQuestionId, currentAnswer); // Ensure current answer is saved
      setCurrentQuestionIndex(currentQuestionIndex + 1);
      currentQuestionIdRef.current = currentExam?.current_module?.questions[currentQuestionIndex + 1]?.id;
      currentAnswerRef.current = selectedAnswers[currentExam?.current_module?.questions[currentQuestionIndex + 1]?.id] || '';

    } else {
      openReviewPage();
    }
  };
    
  const handlePreviousQuestion = () => {
    const currentQuestionId = currentExam?.current_module?.questions[currentQuestionIndex]?.id;
    const currentAnswer = selectedAnswers[currentQuestionId];
    if (currentQuestionIndex > 0) {
      saveCurrentAnswer(currentQuestionId, currentAnswer); // Ensure current answer is saved
        setCurrentQuestionIndex(currentQuestionIndex - 1);
        currentQuestionIdRef.current = currentExam?.current_module?.questions[currentQuestionIndex - 1]?.id;
        currentAnswerRef.current = selectedAnswers[currentExam?.current_module?.questions[currentQuestionIndex - 1]?.id] || '';

    }
};
    

const handleTimeout = useCallback(async () => {
  const currentQuestionId = currentQuestionIdRef.current;
  const currentAnswer = currentAnswerRef.current;

  try {
      // Ensure the current answer is saved immediately
      if (currentAnswer !== undefined && validateInput(currentAnswer)) {
          await dispatch(autosaveAnswer(currentQuestionId, currentAnswer, studentexamId));
          setLastSavedAnswer(prev => ({ ...prev, [currentQuestionId]: currentAnswer }));
      }

      // Transition to the next module
      await handleModuleTransition();
  } catch (error) {
      console.error('Error during handleTimeout:', error);
      // Handle error or retry logic here if needed
  }
}, [dispatch, studentexamId, handleModuleTransition, validateInput]);

  


  const handleSelectQuestion = (index) => {
    setCurrentQuestionIndex(index);
    const newQuestionId = currentExam.current_module.questions[index].id;
    const newAnswer = selectedAnswers[newQuestionId] || '';
    setUserInput(newAnswer); // Update userInput to reflect the selected question's answer
};


  
  const isQuestionAnswered = useCallback((questionId) => {
    return selectedAnswers[questionId] !== undefined;
}, [selectedAnswers]);

const isMarkedReview = useCallback((questionId) => {
  // console.log('isMarked for review',questionId,markedForReview[questionId] !== undefined)
  // console.log(markedForReview)
  return markedForReview[questionId] === true;
}, [markedForReview]);


const handleAnswerSelect = (questionId, event, isMCQuestion) => {
  const answer = isMCQuestion ? event.target.value : userInput;
  currentQuestionIdRef.current = questionId;
        currentAnswerRef.current = answer;
  setSelectedAnswers(prev => ({ ...prev, [questionId]: answer }));

  debouncedDispatch(questionId,answer);
};

const renderInputError = () => {
  if (inputError) {
      return (
          <Typography color="error" style={{ marginTop: '10px' }}>
              {inputError}
          </Typography>
      );
  }
  return null;
};

if (loading) return <LoadingOverlay/>
  if (error) return <Typography color="error">{error}</Typography>;
  // if (!currentExam || !currentExam.current_module) return <Typography>No exam data available.</Typography>;
  if (!currentExam || !currentExam.current_module || currentExam.current_module.questions.length === 0) {
    return <Typography>No questions available for this exam.</Typography>;
}
  const currentQuestion = currentExam.current_module.questions[currentQuestionIndex];
  const userAnswer = selectedAnswers[currentQuestion.id];
  const isMCQuestion = currentQuestion.question_type === 'MC';
  if (onBreak) {
    return (
      <BreakPage
      breakendTime={breakendTime}
        onSkipBreak={handleSkipBreak}
        user={user}
      />
    );
  }const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };


    return (
        <FocusLock>
           <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleCloseSnackbar}>
    <MuiAlert onClose={handleCloseSnackbar} severity="info" sx={{ width: '100%' }}>
      Transitioning to the next module...
    </MuiAlert>
  </Snackbar>
          {showReviewPage ? (
        <ReviewPageComponent
          onClose={closeReviewPage}
          endTime={endTime}
          handleModuleTransition={handleModuleTransition} // Pass the function as a prop
          
          questions={currentExam.current_module.questions.map((q, index) => ({
            id: q.id,
            text: `Question ${index + 1}`,
            answered: isQuestionAnswered(q.id)
          }))}
          currentQuestionIndex={currentQuestionIndex}
          onSelectQuestion={handleSelectQuestion}
          currentExam={currentExam}
          breakendTime={breakendTime}
          // handleTimeout={handleTimeout}
          user={user}
          isMinimapVisible={isMinimapVisible}
          minimapRef={minimapRef}
          isQuestionAnswered={isQuestionAnswered}
          toggleMinimap={toggleMinimap}
          handleSelectQuestion={handleSelectQuestion}
          openReviewPage={openReviewPage}
          handlePreviousQuestion={handlePreviousQuestion}
          handleNextQuestion={handleNextQuestion}
          showReviewPage={showReviewPage}
          order={currentExam?.current_module?.name}
          isMarkedReview={isMarkedReview}
        />
      )  : (
    !onBreak &&  (<div className="flex-container">

<TopHeader
        currentExam={currentExam}
        endTime={endTime}
        handleTimeout={handleTimeout}
        isMathModule={isMathModule}
        toggleCalculator={toggleCalculator}
        toggleReference={toggleReference}
        myCalculatorRef={myCalculatorRef}
      />

    

<div className="page-wrapper" id="sandbox">
  
      <div className="sidebar-wrapper middle" data-simplebar>
        {/* <div className="resize left"><img className="resizer-img" src="https://digital-sat.aoneinstitutesat.com/icons/resize.png" alt="resize layout" /></div> */}
        <div className="sidebar-content"  dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(processHtmlContent(currentQuestion.text)) }}  />
      </div>


      <div className="page-content-wrapper middle" data-simplebar>
        
        {/* <div className="resize right"><img className="resizer-img right" src="https://digital-sat.aoneinstitutesat.com/icons/resize.png" alt="resize layout" /></div> */}
        <MultipleChoiceQuestion
                        question={DOMPurify.sanitize(currentQuestion.description)}
                        options={Object.entries(currentQuestion.choices || {}).map(([key, value]) => value)}
                        selectedValue={userAnswer}
                        onValueChange={isMCQuestion ? (event) => handleAnswerSelect(currentQuestion.id, event, true) : handleInputChange}
                        questionNumber={currentQuestionIndex + 1}
                        isMCQuestion={isMCQuestion}
                        onBlur={handleInputBlur}
                        markedForReview={markedForReview[currentQuestion.id]}
                        onMarkForReview={() => toggleMarkForReview(currentQuestion.id)}
                        crossedOut={crossedOut[currentQuestion.id] }
                        toggleCrossedOut={(index) => toggleCrossedOut(currentQuestion.id, index)} 
                        toggleTrueCrossedOut={(index) => toggleTrueCrossedOut(currentQuestion.id, index)} 
                        crossIcon={crossedIcon}
                        toggleCrossIcon={() => setCrossedIcon(!crossedIcon)}
                        
                        />

            {renderInputError()}
      </div>
    </div>

     <CalculatorComponent
             isVisible={calculatorVisible}
     toggleVisibility={() => setCalculatorVisible(!calculatorVisible)}
     position={calculatorPosition}
     onPositionChange={setCalculatorPosition}
     isExpanded={isCalculatorExpanded}
     setIsExpanded={setIsCalculatorExpanded}
     myCalculatorRef={myCalculatorRef}
     toggleCalculator={toggleCalculator}
     isCalculator={true}
   />
        <CalculatorComponent
             isVisible={referenceVisible}
     toggleVisibility={() => setReferenceVisible(!referenceVisible)}
     position={referencePosition}
     
     onPositionChange={setReferencePosition}
    //  isExpanded={isCalculatorExpanded}
    //  setIsExpanded={setIsCalculatorExpanded}
     myCalculatorRef={myCalculatorRef}
     toggleCalculator={toggleReference}
     isCalculator={false}
   />
    

{/* <DesmosCalculator ref={myCalculatorRef} /> */}

    
    <Footer
        user={user}
        isMinimapVisible={isMinimapVisible}
        minimapRef={minimapRef}
        currentExam={currentExam}
        currentQuestionIndex={currentQuestionIndex}
        isQuestionAnswered={isQuestionAnswered}
        toggleMinimap={toggleMinimap}
        handleSelectQuestion={handleSelectQuestion}
        openReviewPage={openReviewPage}
        handlePreviousQuestion={handlePreviousQuestion}
        handleNextQuestion={handleNextQuestion}
        showReviewPage={showReviewPage}
        setMiniMapFalse={setMiniMapFalse}
        isMarkedReview={isMarkedReview}
      />
</div>)
)}
        </FocusLock>
    );
};

export default TestPage;