import { Fragment, useState } from "react";
import { Sidebar, Menu, MenuItem, sidebarClasses } from 'react-pro-sidebar';
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { tokens } from "../../theme";
import { logout } from '../../actions/auth';
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import LibraryBooksOutlinedIcon from '@mui/icons-material/LibraryBooksOutlined';
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';

import LoginOutlinedIcon from '@mui/icons-material/LoginOutlined';
import CalendarViewDayOutlinedIcon from '@mui/icons-material/CalendarViewDayOutlined'; // Example icon for schedules
import TipsAndUpdatesOutlinedIcon from '@mui/icons-material/TipsAndUpdatesOutlined';
const SidebarItem = ({ title, to, icon, isSelected ,onClick}) => (
  <Link to={to} style={{ textDecoration: 'none', color: 'inherit' }}>
    <MenuItem
      active={isSelected}
      icon={icon}
      style={isSelected ? { color: '#6870fa' } : {}}
      onClick = {onClick}
    >
      <Typography>{title}</Typography>
    </MenuItem>
  </Link>
);

const Sidebar1 = () => {
  const version = '2.3.6.3'; 

  const user = useSelector(state => state.auth.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const { first_name, last_name, group_names, email } = user || {};
  const role = group_names?.[0] || '';
  const roleLabel = role.charAt(0).toUpperCase() + role.slice(1);
  // console.log('role',role)

  const logoutUser = () => {
    logout()(dispatch);
    setTimeout(() => navigate('/login'), 100);
  };

  return (
<Sidebar
  collapsed={isCollapsed}
  rootStyles={{
    [`.${sidebarClasses.container}`]: {
      // background: `${colors.primary[400]} !important`,
      height: '100vh', // Ensures sidebar takes full viewport height
      position: 'fix', // Makes sidebar sticky
      top: 0, // Aligns to the top of the viewport
      overflowY: 'auto' // Allows sidebar to scroll independently if content is taller than the viewport
    }
  }}
>

      <Menu>
        {isCollapsed ? (
          <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
            <MenuOutlinedIcon />
          </IconButton>
        ) : (
          <Box display="flex" justifyContent="space-between" alignItems="center" ml="15px">
            <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
              <MenuOutlinedIcon />
            </IconButton>
          </Box>
        )}

        { !isCollapsed && <UserInfo {...{ first_name, last_name, email, roleLabel, colors,version }} /> }

        <Box paddingLeft={isCollapsed ? undefined : "10%"}>


          <SidebarItem title="Dashboard" to="/" icon={<HomeOutlinedIcon />} isSelected={location.pathname === '/'} />

          {role !== 'student' && (
            <Fragment>
              <SidebarGroup title="Exam Pages" colors={colors}>
              <SidebarItem title="Manage Exam" to="/manage-exams" icon={<LibraryBooksOutlinedIcon />} isSelected={location.pathname === '/manage-exams'} />
              <SidebarItem title="Exam Schedules" to="/schedules" icon={<CalendarViewDayOutlinedIcon />} isSelected={location.pathname === '/schedules'} />

              </SidebarGroup>
            </Fragment>
          )}

          <SidebarItem title="My Exams" to="/my-exams" icon={<SchoolOutlinedIcon />} isSelected={location.pathname === '/my-exams'} />

          {(role === 'admin' || role === 'teacher' || role === 'staff') && (
            <SidebarGroup title="User Pages" colors={colors}>
            <SidebarItem title="User Management" to="/team" icon={<PeopleOutlinedIcon />} isSelected={location.pathname === '/team'} />

            </SidebarGroup>
          )}

          <SidebarItem title="Sign Out" onClick={logoutUser} icon={<LoginOutlinedIcon />} />
        </Box>
      </Menu>
    </Sidebar>
  );
};

const UserInfo = ({ first_name, last_name, email, roleLabel, colors,version }) => (
  
  <Box mb="25px" textAlign="center">
    <Typography variant="h5" style={{ color: colors.grey[100], fontWeight: "bold" }}>
      LIGHTEN TEST
    </Typography>

    <Typography variant="h2" color={colors.grey[100]} sx={{ m: "5x 0 0 0" }}>{first_name} {last_name}</Typography>
    <Typography variant="subtitle1" color={colors.grey[700]}>{email}</Typography>
    <Typography variant="h5" color={colors.greenAccent[500]}>{roleLabel}</Typography>
    <Link to="/release-notes" style={{ textDecoration: 'none',  color: '#757575' }}>
  <TipsAndUpdatesOutlinedIcon style={{ fontSize: '15px' }}/> {version}
</Link>
  </Box>
);

const SidebarGroup = ({ title, children, colors }) => (
  <Fragment>
    <Typography variant="h6" color={colors.grey[300]} sx={{ m: "15px 0 5px 20px", textAlign: 'left' }}>{title}</Typography>
    {children}
  </Fragment>
);

export default Sidebar1;
